export default class Executive {
  constructor(
    salutation,
    firstname,
    lastname,
    designationId,
    email,
    address,
    phone,
    highestEducation,
    photo,
    voice,
    // fiscalYearId,
    canLogin = false,
    facebookLink,
    twitterLink,
    linkedinLink
  ) {
    this.salutation = salutation;
    this.first_name = firstname;
    this.last_name = lastname;
    this.email = email;
    this.address = address;
    this.phone = phone;
    this.highest_education = highestEducation;
    this.designation_id = designationId;
    this.photo = photo;
    this.voice = voice;
    // this.fiscal_year_id = fiscalYearId;
    this.can_login = canLogin;
    this.facebook_link = facebookLink;
    this.twitter_link = twitterLink;
    this.linkedin_link = linkedinLink;
  }
}
